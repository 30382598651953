<script>
/* global moment */
import TableLink from './TableLink.vue'
import FormFilter from './FormFilter.vue'
import Layout from '@/components/Layout.vue'
import CommonTable from '@/components/CommonTable.vue'
import { mapState, mapActions } from 'vuex'
import { format } from '@/lib/plugins/date'
import { rolePermissionCheck } from '@/permission/rolePermission'

export default {
	name: 'AuditLogs',
	components: {
		TableLink,
		CommonTable,
		Layout,
		FormFilter
	},
	data() {
		return {
			columns: [
				{
					name: 'createdAt',
					label: `Created At (${format(moment(), null, 'UTCZ')})`,
					field: 'createdAt',
					format: this.$dateUtils.unaryFormat,
					align: 'left',
					sortable: true,
					style: 'min-width: 180px; max-width: 240px'
				},
				{
					name: 'userId',
					label: 'User ID',
					field: 'userId',
					align: 'left',
					sortable: true,
					style: 'min-width: 95px; max-width: 120px'
				},
				{
					name: 'orderId',
					label: 'Order ID',
					field: 'orderId',
					align: 'left',
					sortable: true,
					style: 'min-width: 250px'
				},
				{
					name: 'deliveryId',
					label: 'Delivery ID',
					field: 'deliveryId',
					align: 'left',
					sortable: true,
					style: 'min-width: 250px'
				},
				{
					name: 'action',
					label: 'Action',
					field: 'action',
					align: 'left',
					style: 'min-width: 190px; max-width: 250px'
				},
				{
					name: 'status',
					label: 'Status',
					field: 'status',
					align: 'left',
					style: 'min-width: 150px; max-width: 200px'
				},
				{
					name: 'detail',
					label: 'Detail',
					align: 'left',
					style: 'min-width: 90px; max-width: 120px'
				}
			]
		}
	},
	computed: {
		...mapState('auditLogs', ['totalPage', 'data', 'filter']),
		detailPermission() {
			return rolePermissionCheck('audit-logs', 'detail')
		}
	},
	methods: {
		...mapActions('auditLogs', ['getAuditLogs', 'updateFilter']),
		async onPageChange(pagination) {
			const { sortBy, descending, page, limit } = pagination

			await this.getAuditLogs({
				sortBy: sortBy || 'createdAt',
				sortDirection: sortBy && !descending ? 'ASC' : 'DESC',
				page,
				limit
			})
		}
	}
}
</script>

<template>
	<layout class-name="audit-log-page" title="Audit Logs">
		<form-filter />
		<common-table
			row-key="id"
			:data="data"
			:columns="columns"
			:on-page-change="onPageChange"
			:total-page="totalPage"
			:current-page="filter.page"
			:current-sort="{
				sortBy: filter.sortBy,
				descending: filter.sortDirection === 'DESC'
			}"
			binary-state-sort
		>
			<template #userId="{ row }">
				<table-link :to="`users/${row.userId}`" target="_blank">
					{{ row.userId }}
				</table-link>
			</template>

			<template #orderId="{ row }">
				<table-link :to="`orders/${row.orderId}`" target="_blank">
					{{ row.orderId }}
				</table-link>
				<div>
					{{ row.orderRefs }}
				</div>
			</template>

			<template #deliveryId="{ row }">
				<table-link
					:to="`deliveries/${row.deliveryId}`"
					target="_blank"
				>
					{{ row.deliveryId }}
				</table-link>
				<div>
					{{ row.parcelRefs }}
				</div>
			</template>

			<template v-if="detailPermission" #detail="{ row }">
				<table-link :to="`audit-logs/${row.id}`" link-decoration="none">
					🗒️
				</table-link>
			</template>
		</common-table>
	</layout>
</template>

<style lang="scss">
.audit-log-page {
	display: flex;
}
.q-table {
	width: -moz-available;
}
</style>
