<template>
	<router-link
		:to="{ path: to }"
		:style="{ 'text-decoration': linkDecoration }"
	>
		<slot />
	</router-link>
</template>

<script>
export default {
	name: 'TableLink',
	props: {
		to: {
			type: String,
			required: true
		},
		linkDecoration: {
			type: String,
			default: 'underline'
		}
	}
}
</script>
