<script>
import _ from 'lodash'
import { mapActions, mapState } from 'vuex'
import DateInput from '@/components/DateInput.vue'
import FormInput from '@/components/FormInput.vue'
import FormSelect from '@/components/FormSelect.vue'
import CommonForm from '@/components/CommonForm.vue'

export default {
	name: 'FormFilter',
	components: { CommonForm, DateInput, FormInput, FormSelect },
	data() {
		return {
			userId: null,
			orderId: null,
			deliveryId: null,
			orderRef: null,
			parcelRef: null,
			action: null,
			actionOptions: [],
			status: null,
			statusOptions: [],
			minCreatedAt: null,
			maxCreatedAt: null
		}
	},
	computed: {
		...mapState('auditLogs', ['filter', 'optionList'])
	},
	async created() {
		if (this.optionList.actionOptions.length === 0) {
			await this.getActionList()
		}

		this.syncLocalWithStore()
	},
	beforeDestroy() {
		this.resetFilter()
	},
	methods: {
		...mapActions('auditLogs', [
			'getActionList',
			'getAuditLogs',
			'resetFilter'
		]),
		syncLocalWithStore(filter = this.filter, optionList = this.optionList) {
			//	Sync option lists
			this.actionOptions = optionList.actionOptions
			this.statusOptions = optionList.statusOptions

			//	Sync filters
			this.userId = filter.userId
			this.orderId = filter.orderId
			this.deliveryId = filter.deliveryId
			this.orderRef = filter.orderRef
			this.parcelRef = filter.parcelRef
			const actionOption = _.find(optionList.actionOptions, {
				value: filter.action
			})
			this.action = _.get(actionOption, 'label', null)
			const statusOption = _.find(optionList.statusOptions, {
				value: filter.status
			})
			this.status = _.get(statusOption, 'label', null)
			this.minCreatedAt = filter.minCreatedAt
			this.maxCreatedAt = filter.maxCreatedAt
		},
		async submit() {
			const { minCreatedAt, maxCreatedAt } = this

			const statusOption = _.find(this.statusOptions, {
				value: this.status
			})

			//	Load audit log with filter value
			return this.getAuditLogs({
				userId: _.get(this, 'userId', null),
				orderId: _.get(this, 'orderId', null),
				deliveryId: _.get(this, 'deliveryId', null),
				orderRef: _.get(this, 'orderRef', null),
				parcelRef: _.get(this, 'parcelRef', null),
				action: _.get(this, 'action', null),
				status: _.get(statusOption, 'value', null),
				minCreatedAt,
				maxCreatedAt
			})
		},
		async reset() {
			this.resetFilter()
			this.syncLocalWithStore()
			await this.submit()
		}
	}
}
</script>

<template>
	<common-form :reset="reset" :submit="submit">
		<div class="audit-logs-filter">
			<div>
				<form-input
					v-model="userId"
					label="User ID"
					:rules="$validator.nullableID('User ID')"
				/>
				<form-input
					v-model="orderId"
					label="Order ID"
					:rules="$validator.nullableID('Order ID')"
				/>
				<form-input
					v-model="deliveryId"
					label="Delivery ID"
					:rules="$validator.nullableID('Delivery ID')"
				/>
				<form-select
					v-model="action"
					:options="actionOptions"
					label="Action"
					styles="width: 250px"
				/>
			</div>
			<div>
				<form-select
					v-model="status"
					:options="statusOptions"
					label="Status"
				/>
				<form-input v-model.trim="orderRef" label="Order Ref ID" />
				<form-input v-model.trim="parcelRef" label="Parcel Ref ID" />
				<date-input
					v-model="minCreatedAt"
					label="Created Time (MIN)"
					jquery
				/>
				<date-input
					v-model="maxCreatedAt"
					label="Created Time (MAX)"
					jquery
				/>
			</div>
		</div>
	</common-form>
</template>

<style lang="scss">
.audit-logs-popup-content {
	.q-item {
		width: 100%;
		margin: 0;
	}
}
.audit-logs-filter {
	display: flex;
	flex-direction: column;
	grid-gap: 10px;

	> div {
		display: flex;
		flex-wrap: wrap;
		grid-gap: 10px;
	}
}
</style>
